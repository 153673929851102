import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaYoutube, FaTelegramPlane } from 'react-icons/fa'; // Иконки соцсетей
import { RiGlobalLine } from 'react-icons/ri'; // Иконка для языка
import { FaSun, FaMoon } from 'react-icons/fa'; // Иконки для света и темноты
import { Switch } from '@mui/material'; // Переключатель темы
import '../asstes/css/Header.css'; // Стили для хедера
import uz from '../asstes/images/uz.png'
import gb from '../asstes/images/gb.png'

const Header = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') === 'dark' ? true : false);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [showLangDropdown, setShowLangDropdown] = useState(false);

  // Загружаем тему из localStorage при старте
  useEffect(() => {
    if (theme) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [theme]);

  // Переключение темы
  const toggleTheme = (event) => {
    setTheme(event.target.checked);
    localStorage.setItem('theme', event.target.checked ? 'dark' : 'light');
  };

  // Переключение языка
  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    setShowLangDropdown(false);
  };

  // Получение флага текущего языка
  const getCurrentLanguageFlag = () => {
    if (language === 'en') {
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/1280px-Flag_of_the_United_Kingdom_%281-2%29.svg.png'; // Замените на путь к флагу
    } else if (language === 'uz') {
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/1280px-Flag_of_Uzbekistan.svg.png'; // Замените на путь к флагу
    }
    return '';
  };

  return (
    <header className="header">
      <div className="container">
        <div className="left-header">
          <div className="socials">
            <Link to="/" target='_blank' className='social-link'>
              <FaFacebookF className='social-icon' />
            </Link>
            <Link to="/" className='social-link'>
              <FaInstagram className='social-icon' />
            </Link>
            <Link to="/" className='social-link'>
              <FaYoutube className='social-icon' />
            </Link>
            <Link to="/" className='social-link'>
              <FaTelegramPlane className='social-icon' />
            </Link>
          </div>
          <Link to="/" className="mail">
            <span>Elektron pochta:</span>
            <span>info@qoqon.depo2.uz</span>
          </Link>
        </div>

        <div className="right-header">
        <Link to="/davlat-ramzlari"  className="emblems">
            <span>Davlat ramzlari</span>
          </Link>
          <Link to=""  className='schema-site'>
            <span>Sayt xaritasi</span>
          </Link>

          {/* Переключатель темы с иконками */}
          <div className="theme-toggle">
            <div className="theme-label">
              <FaSun className={`theme-icon ${theme ? 'hidden' : ''}`} />
              <FaMoon className={`theme-icon ${theme ? '' : 'hidden'}`} />
              <Switch
                checked={theme}
                onChange={toggleTheme}
                name="themeSwitch"
                inputProps={{ 'aria-label': 'controlled' }}
                sx={{
                  transform: 'scale(0.7)', // Уменьшаем размер переключателя
                }}
              />
            </div>
          </div>

          {/* Выбор языка с флагом, отображаем флаг рядом с глобусом */}
          <div className="language-dropdown">
            <button className="lang-btn" onClick={() => setShowLangDropdown(!showLangDropdown)}>
              <RiGlobalLine  className='lang-icon'/>
              <span className="current-lang-text">
                <img src={getCurrentLanguageFlag()} alt={language} className="lang-flag" />
                {language === 'en' ? 'English' : "O'zbekcha"}
              </span>
            </button>
            {showLangDropdown && (
              <div className="lang-options">
                <button onClick={() => changeLanguage('en')} className="lang-option">
                  <img src={gb} alt="English" className="lang-flag" /> English
                </button>
                <button onClick={() => changeLanguage('uz')} className="lang-option">
                  <img src={uz} alt="Uzbek" className="lang-flag" /> O'zbekcha
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
