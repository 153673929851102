import React, { useState } from 'react';
import '../asstes/css/emblems.css';
import { Link } from 'react-router-dom';
import row from '../asstes/images/right.svg';
import home from '../asstes/images/home.svg';
import right from '../asstes/images/arrow-right.svg';
import print from '../asstes/images/print.svg';
import location from '../asstes/images/location-icon.svg';
import phone from '../asstes/images/phone-icon.svg';
import link from '../asstes/images/Icon-contact.svg';
import mail from '../asstes/images/icon-message.svg';
import faks from '../asstes/images/faks.svg';
import calendar from '../asstes/images/icon-calendar.svg';
import script from '../asstes/images/Icon-script.svg'
import '../asstes/css/contact.css';

const Emblems = () => {
    // Для состояния dropdown
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedType, setSelectedType] = useState('Tanlash');
    
    // Открытие/закрытие dropdown
    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    // Выбор элемента из dropdown
    const handleSelect = (value) => {
        setSelectedType(value);
        setDropdownOpen(false); // Закрытие dropdown после выбора
    };

    return (
        <div className="emblem-container">

                <div className="emblem-title">
                    <div className="container">
                        <h1>Murojaat va shikoyatlar</h1>
                        <div>
                            <div className="left-side-topmenu">
                                <ul style={{paddingLeft: '0'}}>
                                    <li>
                                        <img src={home} alt="home" />
                                        <a href="/">Asosiy sahifa</a>
                                        <meta property="position" content="1" />
                                    </li>
                                    <li className="active">
                                        <img src={right} alt="right" />
                                        <a>Murojaat va shikoyatlar</a>
                                        <meta property="position" content="2" />
                                    </li>
                                </ul>
                            </div>
                            <div className="right-side-topmenu">
                                <a href="#" onClick={() => window.print()} style={{ display: 'none' }}>
                                    <img src={print} alt="" />
                                    Chop etish
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-ministry">
                    <div className="container">
                        <div className="left-side-contact">
                            <h1 className="header-contact-page">Depo bilan bog‘lanish</h1>
                            <div className="info-contact">
                                <img src={location} alt="" />
                                <div>
                                    <h6>Manzil</h6>
                                    <p>
                                        Qo'qon shahri, Zarbuloq ko‘chasi, 48-uy <br />
                                        Mo'ljal: 7-sonli umumta'lim maktabi <br />
                                    </p>
                                    <Link to="" className="link_all">Xaritada ko‘rish <img src={row} alt="row" /></Link>
                                </div>
                            </div>
                            <div className="info-contact">
                                <img src={phone} alt="" />
                                <div>
                                    <h6>Telefon raqami</h6>
                                    <Link to="" className="link-contact"><img src={link} alt="" /> +998 (73) 543-13-54 </Link>
                                </div>
                            </div>
                            <div className="info-contact">
                                <img src={mail} alt="" />
                                <div>
                                    <h6>Elektron pochta manzili</h6>
                                    <Link to="" className="link-contact"><img src={link} alt="" /> info@qoqon.depo2.uz</Link>
                                </div>
                            </div>
                            <div className="info-contact">
                                <img src={faks} alt="" />
                                <div>
                                    <h6>Faks</h6>
                                    <Link to="" className="link-contact"><img src={link} alt="" /> </Link>
                                </div>
                            </div>
                            <div className="info-contact">
                                <img src={calendar} alt="" />
                                <div>
                                    <h6>Ish vaqti</h6>
                                    <span>Dushanba - juma</span>
                                    <span>08:00 - 17:00</span>
                                </div>
                            </div>
                        </div>
                        <div className="right-side-contact">
                            <h1 className="header-contact-page" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                Murojaat yuborish
                                <a href="https://murojaat.gov.uz/" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: '120px', scale: '1.4' }} src="https://mintrans.uz/assets/img/svg/murojaatlogo.svg" alt="" />
                                </a>
                            </h1>
                            <div className="send-link">
                                <a data-tab="send-id-1" className="current">Murojaat yuborish</a>
                                <a data-tab="send-id-2">Murojaat holatini tekshirish</a>
                            </div>
                            <form id="send-id-1" className="form-fill-contact current" action="" method="POST" encType="multipart/form-data">
                                <input type="hidden" name="language" value="oz" />
                                <label>F.I.SH.</label>
                                <input type="text" id="fio" name="fio" placeholder="F.I.SH. to'liq kiriting" required style={{ marginBottom: '30px' }} />
                                <div>
                                    <label>Telefon raqami
                                        <input type="number" id="phone" name="phone" placeholder="+998 (_ _)  _ _ _  _ _  _ _" required />
                                    </label>
                                    <label>E-pochta manzili
                                        <input type="email" id="email" name="email" placeholder="Elektron pochta manzilini kiriting" required />
                                    </label>
                                    <label className="message-subject">Murojaat mavzusi
                                        <input type="text" name="content_type" placeholder="Murojaat mavzusi" required className="m-0" />
                                    </label>
                                    <input type="hidden" id="categoryhidden" name="category" />
                                    <label className="type-subject">Murojaat turi
                                    <div className="current-statistic">
                                      <select className="dropdown-statistic" id="categoryhidden">
                                          <option value="public">Ommaviy</option>
                                          <option value="private">Xususiy</option>
                                          <option value="Umumiy savollar">Umumiy savollar</option>
                                          <option value="Shikoyat">Shikoyat</option>
                                          <option value="Bo‘sh ish o‘rinlari">Bo‘sh ish o‘rinlari</option>
                                          <option value="Yo‘lovchi tashish faoliyati">Yo‘lovchi tashish faoliyati</option>
                                          <option value="Temir yo‘l faoliyati">Temir yo‘l faoliyati</option>
                                      </select>
                                  </div>
                              </label>
                                    <label className="textarea-contact">Murojaat matni
                                        <textarea name="text" cols="30" rows="10" placeholder="Murojaat matnini batafsil kiriting"></textarea>
                                    </label>
                                    <label htmlFor="file" className="file-contact" id="file_label">
                                        <img src={script} alt="" />
                                        Fayl biriktirish
                                        <input type="file" id="file" name="file" className='input-file-contact'/>
                                    </label>
                                    <div className='files_result'></div>
                                </div>
                                <div class="bottom-contact-page">
                                        <label class="input-form-checkbox" for="">
                                            <input type="checkbox"/>
                                             <span>Ushbu murojaatimni yuborish orqali Qo'qon lokomotiv deposi veb-saytining <a href=""> maxfiyligi shartlari </a>ga roziligimni bildiraman.</span>
                                        </label>
                                        <button class="btn-submit-contact" type="submit">Yuborish</button>
                                    </div>
                            </form>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Emblems;
