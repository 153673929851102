import React from 'react';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'
const Emblems = () => {
    return (
        <div className='emblem-container'>
           
                <div className='emblem-title'>
                  <div className='container'>
                    <h1>Texnik hujjatlar</h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft:'0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <a href="/">Asosiy sahifa</a>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li class="active">
                                    <img src={right} alt='right'/>
                                     <a>Temir yo'l sohasiga oid texnik hujjatlar </a>
                                     <meta property="position" content="2"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <a href="#" onclick="window.print()">
                                <img src={print} alt=""/>
                            Chop etish
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='document-container'>
                    <div className='conatiner'>
                        <div className='document-cards'>
                                <a href='https://qoq.depo2.uz/files/PTE.pdf' className='document-card'>
                                    <div className='top-img'>
                                        <img src='https://railway.uz/local/templates/main_v2/img/logo.png' width={55}/>
                                    </div>
                                    <p>Oʻzbekiston Respublikasi sanoat korxonalarining temir yoʻllaridan texnikaviy foydalanish qoidalari</p>
                                    <div class="data-bottom-doc">
                                        <span><img src="http://qoq.depo2.uz/files/pdf.png" alt="" width={25}/></span>
                                        <span style={{color: '#333'}}>0.69 Мб</span>
                                    </div>
                                </a>
                                <a href='https://qoq.depo2.uz/files/ISI.pdf' className='document-card'>
                                    <div className='top-img'>
                                        <img src='https://railway.uz/local/templates/main_v2/img/logo.png' width={55}/>
                                    </div>
                                    <p>O’zbekiston Respublikasi temir yo’llarida signallashtirish bo’yicha yo’riqnoma</p>
                                    <div class="data-bottom-doc">
                                        <span><img src="http://qoq.depo2.uz/files/pdf.png" alt="" width={25}/></span>
                                        <span style={{color: '#333'}}>7.12 Мб</span>
                                    </div>
                                </a>
                                <a href='https://qoq.depo2.uz/files/man.pdf' className='document-card'>
                                   <div className='top-img'>
                                        <img src='https://railway.uz/local/templates/main_v2/img/logo.png' width={55}/>
                                    </div>
                                    <p>Ơzbekiston Respublikasi temir yo’llarida poezdlar harakati va manyovr ishlari bo’yicha yo’riqnomai</p>
                                    <div class="data-bottom-doc">
                                        <span><img src="http://qoq.depo2.uz/files/pdf.png" alt="" width={25}/></span>
                                        <span style={{color: '#333'}}>1.68 Мб</span>
                                    </div>
                                </a>
                        </div>
                    </div>


                </div>
           
        </div>
    );
};

export default Emblems;