import React, { useEffect, useState, useRef } from 'react';
import '../asstes/css/home.css';
import { Link } from 'react-router-dom';
import { FaEye} from 'react-icons/fa';
import { FaArrowLeft, FaArrowRight  } from "react-icons/fa6";
import { GoArrowUp } from "react-icons/go";
import eye from '../asstes/images/eyeshow.svg' ;
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Slider from 'react-slick'; // Импортируем Slider из react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import row from '../asstes/images/right.svg';
import calendar from '../asstes/images/calendar-black.svg';
import elips from '../asstes/images/Ellipse10.svg';
import info1 from '../asstes/images/document-text.svg'
import info2 from '../asstes/images/user-search.svg'
import info3 from '../asstes/images/directbox-default.svg'
import telegram from '../asstes/images/telegram.svg';
import question from '../asstes/images/question.svg'
import mail from '../asstes/images/mail.svg';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
const Home = () => {
    const progressValue = 1;
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const sliderRef = useRef(null);
    const [animation, setAnimation] = useState(false);
    const [activeTab, setActiveTab] = useState("photo"); // Активная вкладка
  const [photos, setPhotos] = useState([]); // Список фотографий
  const [isModalOpen, setIsModalOpen] = useState(false); // Статус модального окна
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Загрузка фотографий с сервера
  useEffect(() => {
    loadPhotos();
  }, []);

  const loadPhotos = async () => {
    try {
      const response = await axios.get("https://qoq.depo2.uz/photos");
      setPhotos(response.data); // Предполагается, что данные уже отсортированы по дате
    } catch (error) {
      console.error("Ошибка при загрузке фотографий:", error);
    }
  };


  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  const lastThreePhotos = photos.slice(-3); // Последние три фотографии

    const handleTabChange = (tab) => {
      setAnimation(true); // Включаем анимацию
      setTimeout(() => {
        setActiveTab(tab); // Меняем активный таб
        setAnimation(false); // Выключаем анимацию после изменения
      }, 300); // Длительность анимации
    };
    const settings = {
          dots: false, // Показывает индикаторы
          infinite: true, // Бесконечная прокрутка
          speed: 500,
          slidesToShow: 3, // Отображать 3 карточки одновременно
          slidesToScroll: 1, // Прокрутка по одной карточке
          swipeToSlide: true, // Позволяет свайпать мышью
          arrows: false, // Отображение стрелок
    };
    useEffect(() => {
        AOS.init({
          duration: 1000, // Длительность анимации в миллисекундах
          once: true, // Анимация срабатывает только один раз
        });
      }, []);

    useEffect(() => {
        const fetchLatestNews = async () => {
          try {
            const response = await axios.get('https://qoq.depo2.uz/news');
            setNews(response.data);
          } catch (error) {
            setError('Error loading news');
          } finally {
            setLoading(false);
          }
        };
        fetchLatestNews();
      }, []);
  
    // Кнопка прокрутки вверх
    useEffect(() => {
        const scrollToTopButton = document.getElementById("scrollToTop");

        const handleScroll = () => {
            if (window.scrollY > 200) {
                scrollToTopButton.style.display = "block";
            } else {
                scrollToTopButton.style.display = "none";
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };


    // Sample news data (use unique IDs for each article)
    const newsArticles = [
        {
            id: 1,
            image: "https://www.gazeta.uz/media/img/2016/06/92Ux8Y14666486141557_l.jpg",
            title: "",
            date: "",
            views: "",
        },
        {
            id: 2,
            image: "https://mintrans.uz/files/original/publication/156_1583382068.jpg",
            title: "",
            date: "",
            views: "",
        },
        {
            id: 3,
            image: "https://lh4.googleusercontent.com/proxy/sq3rrZEHJIwlZzl8Z06aTHvBCuQxRJ9cbgpybDF2BzUOw3A_Bh8HnxMHjhpWc_pXuidLodecXASopNtqzvJWJ3gF3Q7p-trls1NTj_96gQkard795aeWBAe5N_UhKDo",
            title: "",
            date: "",
            views: "",
        },
    ];
    // Данные для карусели
    const carouselImages = [
        "https://mintrans.uz/assets/images/atto2oz.png",
        "https://mintrans.uz/assets/images/atto3oz.png",
    ];
        // Настройки для react-slick
        const carouselSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
        };
    return (
        <>
          {/* Кнопка "Прокрутка вверх" */}
                    <button id="scrollToTop" onClick={handleScrollToTop} style={{ display: 'none' }}>
                    <GoArrowUp style={{fontSize: '25px'}}/>
            </button>
        <div className="home-container">
          <div className='container'>
          <div className="news-section">
            
            <div className="news-large">
                <img src={newsArticles[0].image} alt={newsArticles[0].title} className="news-image" />
                <div className="news-info">
                    <h2 className="news-title">{newsArticles[0].title}</h2>
                    <div className="news-details">
                        <p>{newsArticles[0].date}</p>
                        <span><FaEye /> {newsArticles[0].views}</span>
                    </div>
                </div>
            </div>

            <div className="news-small-container">
                {newsArticles.slice(1).map((article) => (
                    <div className="news-small" key={article.id}>
                        <img src={article.image} alt={article.title} className="news-image" />
                        <div className="news-info">
                            <h3 className="news-title">{article.title}</h3>
                            <div className="news-details">
                                <p>{article.date}</p>
                                <span><FaEye /> {article.views}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
          </div>
    </div>
        <div className='inter-section' data-aos="fade-up">
            <div className='container'>
                <h2>Interaktiv xizmatlar</h2>
                <div className='row'>
                    <Link to="#" className='interactiv-card'>
                     <p><b>Kasbiy bilimlarni oshirib borish uchun online testlar </b> <span></span></p>
                     <span class="counts"><img src={eye} alt="eye"/> 0 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Temir yo'l sohasiga oid davlat qarorlari </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 0 </span>
                    </Link>
                    <Link to="/tehnicheskie-dokumenty" className='interactiv-card'>
                    <p><b>Temir yo'l sohasiga oid texnik hujjatlar </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 3 </span>
                    </Link>
                    {/*<Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>
                    <Link to="#" className='interactiv-card'>
                    <p><b>Xalqaro ruxsatnomalarga onlayn ariza berish </b> <span></span></p>
                    <span class="counts"><img src={eye} alt="eye"/> 61310 </span>
                    </Link>*/}
                </div>
            </div> 
        </div>
        <div className='news-section' data-aos="fade-up">
            <div className='container'>
                <div className='news-section-header'>
                    <h2>Yangiliklar</h2>
                    <Link to="/newsall" className='view-all'>
                    <span>Barchasi</span>
                    <img src={row} alt="row"/>
                    </Link>
                </div>
                <div className="news-container">
    {news.length === 0 ? (
        <p>Нет новостей для отображения</p> // Показываем, если новости пусты
    ) : (
      news.slice(0, 3).map((item, index) =>  ( // Отображаем последние три новости
        <Link key={index} className="news-link" to={`/news-detal/${item.id}`}>
            {item.photo && (
                <img
                    src={item.photo} // Отображаем изображение с правильным путем
                    alt={item.title}
                    className="img-news-link"
                />
            )}
            <div className="info-news">
                <div className="data-time">
                    <span>
                        {item.created_at && (
                            <img src={calendar} alt="calendar" />
                        )}
                        {item.created_at}
                        <img src={eye} alt="eye" />
                        {item.views || 0}
                    </span>
                    <span>
                        {item.category && (
                            <img src={elips} alt="elips" />
                        )}
                        {item.category}
                    </span>
                </div>
                <h3 style={{fontSize: '20px'}}>{item.title}</h3>
                <p>{item.shortDescription}</p>
            </div>
        </Link>
    ))
    
    )}
</div>



            </div>
        </div>
                    {/* Карусель */}
                    <div className="carousel-container">
                      <div className='container'>
                       <Slider {...carouselSettings}>
                          {carouselImages.map((image, index) => (
                              <div key={index} className="carousel-slide">
                                  <img src={image} alt={`Slide ${index + 1}`} />
                              </div>
                          ))}
                      </Slider>
                      </div>
                    </div>
            <div className='info-section' data-aos="fade-up">
                <div className='container'>
                    <div className='jv_services_btns'>
                      <Link to='#' className='jv_item'>
                        <span>
                            <img src={info1} alt='info'/>
                        </span>
                        E’lonlar va tenderlar
                    </Link>
                    <Link to='#' className='jv_item'>
                        <span>
                            <img src={info2} alt='info'/>
                        </span>
                        Bo‘sh ish o‘rinlari
                    </Link>

                    <Link to='#' className='jv_item'>
                        <span>
                            <img src={info3} alt='info'/>
                        </span>
                        Ochiq ma’lumotlar
                    </Link>
                    </div>



                </div>

            </div>
            <div className='content-section'>
                <div className='container'>
                    <div className='content-suggestion'>
                    <p style={{marginBottom: '0'}}>Ko'p beriladigan savollarga javoblar</p>
                    <a href="/faq">Batafsil  <img src={row} alt="row"/></a>
                    </div>

                </div>

            </div>

            <div className='statisttic-section' data-aos="fade-up">
            <div className="container">
                <div className="main-panel">
                    <div className='lokOne'>
                        <div className="left-panel">
                        <h2>Murojaatlar statistikasi</h2>
                        <div className="stat-circle">
                            <CircularProgressbar
                            value={progressValue}
                            text={`${progressValue}`}
                            strokeWidth={15} 
                            styles={buildStyles({
                                textSize: '24px',
                                pathColor: '#09de09',
                                textColor: '#333',
                                trailColor: '#e0e0e0',
                                backgroundColor: '#f3f3f3',
                            })}
                            />
                        </div>
                        <Link to="" className='link-all'>
                             Batafsil
                            <img src={row} alt="row"/>
                        </Link>
                        </div>

                        <div className="right-panel">
                        <h2>Xizmat ko‘rsatish sifatini baholang</h2>
                        <div className='sel'>
                        <label className='lab'>Xizmat turini tanlang:</label>
                        <select className="service-select" disabled>
                            <option>Танлаш</option>
                            <option>Аъло</option>
                            <option>Яхши</option>
                            <option>Қониқарли</option>
                            <option>Қониқарсиз</option>
                        </select>
                        </div>

                        <div className="radio-group">
                            <label><input type="radio" name="rating" /> Аʼlo</label>
                            <label><input type="radio" name="rating" /> Yaxshi</label>
                            <label><input type="radio" name="rating" /> Qoniqarli</label>
                            <label><input type="radio" name="rating" /> Qoniqarsiz</label>
                        </div>
                        <button className="submit-btn">Yuborish</button>
                           <Link to="" className='link-all'>
                            <span>
                              Xizmat sifati boʼyicha taklif yuborish.
                              <img src={row} alt="row"/>
                            </span>
                           </Link>
                        </div>
                    </div>
                    <div className='lokTwo'>

                    </div>
                </div>

                <div className="link-panel">
                    <div className="carousel">
                    <img src="https://mintrans.uz/assets/img/president.png" alt="Slide" className="carousel-image" />
                    <div className="carousel-caption2"> <p>2024-yil "Yoshlar va biznesni qo‘llab-quvvatlash yili"</p></div>
                    </div>
                    <div className="links">
                    <Link to='' className="link-btn"> 
                        <span>
                            <div>
                                <img src={telegram} alt='telegram'/>
                            </div>
                            Telegram kanal
                        </span>  
                        <img src={row} alt="row"/>
                    </Link>
                    <Link to='' className="link-btn"> 
                        <span>
                            <div>
                                <img src={question} alt='question'/>
                            </div>
                            So'rovnoma
                        </span>  
                        <img src={row} alt="row"/>
                    </Link>
                    <Link to='/contact' className="link-btn"> 
                        <span>
                            <div>
                                <img src={mail} alt='mail'/>
                            </div>
                            Murojaat yo'llash
                        </span>  
                        <img src={row} alt="row"/>
                    </Link>
                
                    </div>
                </div>
                </div>

            </div>
            <div className="gallery-container">
      <div className="container">
        <h2>Mediateka</h2>

        {/* Вкладки */}
        <div className="tabs">
          <ul style={{paddingLeft: '0'}}>
            <li
              className={`tab-btn ${activeTab === "photo" ? "active" : ""}`}
              onClick={() => handleTabChange("photo")}
            >
              Fotogalereya
            </li>
            <li
              className={`tab-btn ${activeTab === "video" ? "active" : ""}`}
              onClick={() => handleTabChange("video")}
            >
              Videogalereya
            </li>
          </ul>
        </div>

        {/* Фотогалерея */}
        {activeTab === "photo" && (
          <div className="photo-grid" data-aos="fade-up">
            {lastThreePhotos.map((photo, index) => (
              <img
                key={photo.id}
                src={photo.file_path}
                alt={photo.title}
                onClick={() => openModal(photos.indexOf(photo))}
              />
            ))}
          </div>
        )}

        {/* Видеогалерея */}
        {activeTab === "video" && (
          <div className="video-grid" data-aos="fade-up">
            <video controls>
              <source src="video1.mp4" type="video/mp4" />
              Ваш браузер не поддерживает видео.
            </video>
            <video controls>
              <source src="video2.mp4" type="video/mp4" />
              Ваш браузер не поддерживает видео.
            </video>
            <video controls>
              <source src="video3.mp4" type="video/mp4" />
              Ваш браузер не поддерживает видео.
            </video>
          </div>
        )}
      </div>

      {/* Модальное окно */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="prev-button" onClick={showPrevImage}>
              ◀
            </button>
            <img
              src={photos[currentImageIndex].file_path}
              alt={photos[currentImageIndex].title}
            />
            <button className="next-button" onClick={showNextImage}>
              ▶
            </button>
          </div>
          <button className="close-button" onClick={closeModal}>
            ✖
          </button>
        </div>
      )}
    </div>
            <div className="carousel-container">
              <div className='container'>
              <div className='btnd'>
                <h2>Foydali havolalar</h2>
                <div className="buttons">
                <button onClick={() => sliderRef.current.slickPrev()} className="prev-btn"><FaArrowLeft className='icon'/></button>
                <button onClick={() => sliderRef.current.slickNext()} className="next-btn"><FaArrowRight className='icon'/></button>
                </div>
             </div>
            <Slider {...settings} ref={sliderRef}>
                <Link to='https://president.uz/oz' className="card" style={{borderRadius: '32px'}}>
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>O'zbekiston Respublikasi Prezidentining rasmiy veb sayti</p>
                </Link>
                <Link to='https://pm.gov.uz/uz#/' className="card">
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>O'zbekiston Respublikasi Prezidentining virtual qabulxonasi</p>
                </Link>
                <Link to='https://gov.uz/uz' className="card">
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>O'zbekiston Respublikasi Hukumat portali</p>
                </Link>
                <Link to='https://my.soliq.uz/main/' className="card">
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>Soliq organlarining elektron davlat xizmatlari portali</p>
                </Link>
                <Link to='https://my.gov.uz/uz' className="card">
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>Yagona interaktiv davlat xizmatlari portali</p>
                </Link>
                <Link to='https://iiv.uz/' className="card">
                <img src='https://mintrans.uz/assets/img/gerb_uzbekistana3.png' alt="O'zbekiston Gerb"/>
                <p>O'zbekiston Respublikasi Ichki ishlar vazirligi</p>
                </Link>
            </Slider>
              </div>
            </div>
        </>
    );
};

export default Home;


