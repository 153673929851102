import React, { useState, useEffect } from 'react';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import calendar from '../asstes/images/calendar-black.svg';
import eye from '../asstes/images/eyeshow.svg' ;
import net from '../asstes/images/Icon-net.svg'

const API_URL = 'https://qoq.depo2.uz/';

function NewsDetail() {
  const { id } = useParams();
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const response = await axios.get(`${API_URL}/news/${id}`, { timeout: 5000 });
        setNews(response.data);
        console.log(response.data);  // Проверяем данные
      } catch (error) {
        console.error('Ошибка при получении новости:', error);
        setError('Ошибка при загрузке данных. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchNewsDetail();
  }, [id]);
  
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (loading) return <p>Загрузка...</p>;
  if (error) return <p>{error}</p>;
  

    return (
        <div className='emblem-container'>

                <div className='emblem-title'>
                  <div className='container'>
                    <h1>{news.title}</h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft: '0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <Link to="/">Asosiy sahifa</Link>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li>
                                    <img src={right} alt='right'/>
                                     <Link>Yangiliklar</Link>
                                     <meta property="position" content="2"/>
                                </li>
                                <li>
                                    <img src={right} alt='right'/>
                                     <Link>{news.title} </Link>
                                     <meta property="position" content="3"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <Link to="#" onclick="window.print()">
                                <img src={print} alt=""/>
                            Chop etish
                            </Link>
                          <div className="dropdown-container">
                          <Link to="#" onClick={toggleDropdown} className="btn-share">
                            <img src="https://mintrans.uz/assets/img/svg/share.svg" alt=""/>
                            Sahifani ulashish
                          </Link>
                          {dropdownVisible && (
                            <div className="dropdown">
                              <Link to="https://www.facebook.com/sharer/sharer.php?u=your-url" target="_blank">Facebook</Link>
                              <Link to="https://twitter.com/share?url=your-url" target="_blank">Twitter</Link>
                              <Link to="https://www.linkedin.com/shareArticle?mini=true&url=your-url" target="_blank">LinkedIn</Link>
                            </div>
                          )}
                        </div>
                            
                        </div>
                    </div>
                  </div>
                </div>
                    <div className='news-detal-container'>
                    <img src={`${API_URL}/uploads/${news.photo}`} alt={news.title} style={{ width: '100%', maxHeight: '480px' }} />
                    <div className='tools'>
                        <div>
                           <p><img src={calendar} alt="calendar" /> {news.created_at}</p>
                          <p><img src={eye} alt="eye"/> {news.views}</p>
                        </div>
                        <div>
                         <Link to='/newsall' className='newslist-link'><img src={net} alt="net"/> {news.category}</Link>
                        </div>
                    </div>
                       <div className='news-text'>
                         <p>{news.content}</p>
                       </div>         
                    </div>
        </div>
    );
};

export default NewsDetail;





