import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'
const Emblems = () => {

    const [fileInfo, setFileInfo] = useState(null);
    const fileUrl = 'https://my.depo2.uz/files/kasaba.docx';
    const fileName = '2023 – 2025 yillar uchun TARMOQ JAMOA KELISHUVI';
    useEffect(() => {
      // Функция для получения информации о файле
      const fetchFileDetails = async () => {
        try {
          // Делаем HEAD-запрос для получения информации о файле
          const response = await fetch(fileUrl, { method: 'HEAD' });
  
          if (!response.ok) {
            setFileInfo('Файл не найден');
            return;
          }
  
          // Получаем размер файла и тип из заголовков
          const fileSize = response.headers.get('Content-Length');
          const fileType = response.headers.get('Content-Type');
  
          if (fileSize) {
            const sizeInKB = (fileSize / 1024).toFixed(2); // Размер в КБ
            setFileInfo({
              size: `${sizeInKB} KB`,
              type: fileType || 'неизвестный формат',
            });
          } else {
            setFileInfo('Размер неизвестен');
          }
        } catch (error) {
          setFileInfo('Ошибка при загрузке информации о файле');
          console.error('Error fetching file details:', error);
        }
      };
  
      fetchFileDetails();
    }, [fileUrl]);

    return (
        <div className='emblem-container'>
                <div className='emblem-title'>
                  <div className='container'>
                    <h1>Kasaba uyushmasi </h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft: '0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <a href="/">Asosiy sahifa</a>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li class="active">
                                    <img src={right} alt='right'/>
                                     <a>Kasaba uyushmasi </a>
                                     <meta property="position" content="2"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <a href="#" onclick="window.print()">
                                <img src={print} alt=""/>
                            Chop etish
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
                <div className='emb-container'>
                    <div className='emb-one'>
                         <Link to='' style={{textAlign: 'center', marginBottom: '20px', color: '#333'}}>
                          <h1>O’zbekiston temiryo’lchilari va transport quruvchilari kasaba uyushmalari Respublika Kengashi saytiga o'tish </h1>
                        </Link>
                       
                         <strong>
                           <img src='https://my.depo2.uz/files/kasbs.png'/>   
                         </strong>
                        
                        <p style={{textAlign: 'center', marginBottom: '20px'}}>
                        “Oʻzbekiston  temir  yoʻllari” aksiyadorlik jamiyati boshqaruvi va

                            Oʻzbekiston temiryoʻlchilari va transport quruvchilari kasaba uyushmasi

                            Respublika Kengashi oʻrtasidagi 2023 – 2025 yillar uchun <br />

                            TARMOQ JAMOA KELISHUVI
                        </p>
                        <div style={{marginBottom: '20px'}}>
                        <a href={fileUrl} download={fileName} style={{display: 'flex', alignItems: 'center', textDecoration: 'none', gap:'10px'}}>
                        <img src='https://railway.uz/local/templates/main_v2/img/doc.webp' width={38} height={53}/>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px'}}>
                            {fileInfo && (
                                <span style={{fontSize:'18px', fontFamily: 'Roboto', fontWeight: '500'}}>
                                {typeof fileInfo === 'string' 
                                    ? fileInfo 
                                    : `${fileName}`}
                                </span>
                            )}
                            {fileInfo && (
                                <span style={{fontFamily: 'Roboto', color: '#888', fontWeight: '550'}}>
                                {typeof fileInfo === 'string' 
                                    ? fileInfo 
                                    : `${fileInfo.size}`}
                                </span>
                            )}
                        </div>
                         </a>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default Emblems;