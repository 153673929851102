import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaAnglesLeft, FaAnglesRight  } from "react-icons/fa6";
import topRight from '../asstes/images/top-right-arrow-blue.svg';
import calendar from '../asstes/images/calendar-black.svg';
import eye from '../asstes/images/eyeshow.svg';
import '../asstes/css/newsall.css';
import '../asstes/css/emblems.css';
import home from '../asstes/images/home.svg'
import right from '../asstes/images/arrow-right.svg'
import print from '../asstes/images/print.svg'

const NewsList = () => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [newsPerPage] = useState(5); // Number of items per page

    useEffect(() => {
        const fetchNews = async () => {
            const response = await axios.get('https://qoq.depo2.uz/news');
            setNews(response.data);
        };
        fetchNews();
    }, []);

    // Get current news based on the page
    const indexOfLastNews = currentPage * newsPerPage;
    const indexOfFirstNews = indexOfLastNews - newsPerPage;
    const currentNews = news.slice(indexOfFirstNews, indexOfLastNews);

    // Calculate total pages
    const totalPages = Math.ceil(news.length / newsPerPage);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="news-container">
                        <div className='emblem-title'>
                  <div className='container'>
                    <h1>Yangiliklar</h1>
                    <div>
                        <div className='left-side-topmenu'>
                            <ul style={{paddingLeft: '0'}}>
                                <li>
                                    <img src={home} alt='home'/>
                                    <Link to="/">Asosiy sahifa</Link>
                                    <meta property="position" content="1"></meta>
                                </li>
                                <li class="active">
                                    <img src={right} alt='right'/>
                                     <Link style={{cursor: 'text'}}>Yangiliklar </Link>
                                     <meta property="position" content="2"/>
                                </li>
                            </ul>
                        </div>
                        <div class="right-side-topmenu">
                            <a href="#" onclick="window.print()" style={{display: 'none'}}>
                                <img src={print} alt=""/>
                            Chop etish
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
            <div className="container">
                {news.length === 0 ? (
                    <p>Нет новостей для отображения</p> // If no news
                ) : (
                    currentNews.map((item, index) => (
                        <div key={index} className="news-page-box" to={`/news-detail/${item.id}`}>
                            <Link className="news-item newsall">
                                {/* Image on the left */}
                                {item.photo && (
                                    <img
                                        src={item.photo}
                                        alt={item.title}
                                    />
                                )}
                            </Link>
                            {/* Details on the right */}
                            <div>
                                <h1>{item.title}</h1>
                                <div className="data-time">
                                    <span>
                                        {item.created_at && (
                                            <img src={calendar} alt="calendar" />
                                        )}
                                        {item.created_at}
                                        <img src={eye} alt="eye" />
                                        {item.views || 0}
                                    </span>
                                </div>
                                <p>{item.content.length > 300 ? `${item.content.slice(0, 300)}...` : item.content}</p>
                                <Link to={`/news-detal/${item.id}`} className="link_all">
                                    Batafsil <img src={topRight} alt="" />
                                </Link>
                            </div>
                        </div>
                    ))
                )}
            </div>
            {/* Pagination controls */}
            <div className="pagination">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                  <FaAnglesLeft  className='pan-icon'/>
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <FaAnglesRight className='pan-icon'/>
                </button>
            </div>
        </div>
    );
};

export default NewsList;
