import React, { useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import '../asstes/css/layout.css';
import { FaSearch } from 'react-icons/fa';
import lightLogo from '../asstes/images/logo.png';
import darkLogo from '../asstes/images/white.png';
import right from '../asstes/images/right.svg';
import down from '../asstes/images/down.svg';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const navigate = useNavigate();

  const toggleSearch = () => {
    setSearchOpen((prev) => !prev);
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
      setSearchOpen(false);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  const toggleTheme = () => {
    setIsDarkTheme((prev) => !prev);
  };
  return (
    <>
      <Header />
      <nav className={`navbar ${isDarkTheme ? 'dark-theme' : 'light-theme'}`}>
        <div className="center-navbar container">
          <div className="container">
            <div className="left-center-nav">
              {/* Conditionally render the logo based on the theme */}
              <Link to='/'>
              <img
                src={isDarkTheme ? darkLogo : lightLogo}
                alt="site-logo"
                className="site-logo"
                width={300}
              />
              </Link>
            </div>
            <div className="right-center-nav">
              {/* Toggle hidden class and animate links */}
              <div className={`navbar-btns ${searchOpen ? 'hidden' : 'slide-in'}`}>
                <Link className="btn-vertual">
                  <p style={{marginBottom: '0'}}>Virtual qabulxona</p>
                  <img src={right} alt="right" />
                </Link>
                <Link className="btn-secrt">
                  <span>
                    Ishonch telefoni:
                    <img src={down} alt="down" />
                  </span>
                  <strong> +998 (73) 543-13-54</strong>
                </Link>
              </div>

              <div className={`search-container ${searchOpen ? 'open' : ''}`}>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyPress={handleInputKeyPress}
                  onBlur={() => setSearchOpen(false)}
                />
                {searchOpen && (
                  <FaSearch className="search-icon-inside" onClick={handleSearch} />
                )}
              </div>

              {!searchOpen && (
                <Link className="btn-search" onClick={toggleSearch}>
                  <FaSearch  className='search-icon'/>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="bottom-navbar">
          <div className="container" style={{padding: '0'}}>
          <ul className="navbar-items" style={{paddingLeft: '0'}}>
            <li className="navbar-item" style={{margin: '0px'}}>
                <Link  className="navbar-link">
                  Lokomotiv depo haqida <img src={down} alt='down'/>
                </Link>
                <ul className="dropdown-menu">
                <li><Link to="/about">Depo haqida</Link></li>
                <li><Link to="/home/services">Rahbariyat</Link></li>
                <li><Link to="/regions">Hududiy tarkib</Link></li>
                <li><Link to="/home/contact">Ma'naviyat va ma'rifat</Link></li>
                <li><Link to="/home/contact">Yoshlar ittifoqi</Link></li>
                <li><Link to="/kasaba">Kasaba uyushmasi</Link></li>
                <li><Link to="/home/contact">Korrupsiyaga qarshi kurashish</Link></li>
                </ul>
            </li>
            <li className="navbar-item">
                <Link className="navbar-link">
                  Faoliyat <img src={down} alt='down'/>
                </Link>
                <ul className="dropdown-menu">
                <li><Link to="/blogs/latest">Lokomotivlarni joriy ta'mirlash</Link></li>
                <li><Link to="/blogs/popular">Lokomotivlarga texnik xizmat ko'rsatish</Link></li>
                <li><Link to="/blogs/popular">Yuk va yo'lovchi tashish</Link></li>
                </ul>
            </li>
            <li className="navbar-item">
                <Link className="navbar-link">
                  Axborot xizmati <img src={down} alt='down'/>
                </Link>
                <ul className="dropdown-menu">
                <li><Link to="/newsall">Yangiliklar</Link></li>
                <li><Link to="/contact/phone">Foydali maqolalar</Link></li>
                <li><Link to="/contact/phone">E’lonlar va tenderlar</Link></li>
                <li><Link to="/contact/phone">Mediagalereya</Link></li>
                <li><Link to="/contact/phone">Axborot xizmati bilan bog’lanish</Link></li>
                </ul>
            </li>
            <li className="navbar-item">
                <Link to="/smart" className="navbar-link">
                  Smart Depo
                </Link>
            </li>
            <li className="navbar-item">
                <Link to="/contact" className="navbar-link">
                  Bog'lanish
                </Link>
            </li>
            </ul>
          </div>
        </div>
      </nav>
      <Outlet />
      <Footer/>
    </>
  );
};

export default Layout;
