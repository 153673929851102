import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../asstes/css/adminAddNews.css';  // Добавим файл с кастомными стилями

const AdminAddNews = () => {
    const [title, setTitle] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [photo, setPhoto] = useState(null);
    const [newsList, setNewsList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const newsPerPage = 5;

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setPhoto(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('title', title);
        formData.append('short_description', shortDescription);
        formData.append('content', content);
        formData.append('category', category);
        if (photo) {
            formData.append('photo', photo);
        }

        try {
            await axios.post('https://qoq.depo2.uz/news', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            alert('News added successfully!');
            setTitle('');
            setShortDescription('');
            setContent('');
            setCategory('');
            setPhoto(null);
            setShowForm(false);
            fetchNews();
        } catch (error) {
            console.error(error);
            alert('Error adding news');
        }
    };

    const fetchNews = async () => {
        try {
            const response = await axios.get('https://qoq.depo2.uz/news', {
                params: {
                    _page: currentPage,
                    _limit: newsPerPage
                }
            });
            setNewsList(response.data);
            const totalCountResponse = await axios.get('https://qoq.depo2.uz/news/count');
            setTotalPages(Math.ceil(totalCountResponse.data / newsPerPage));
        } catch (error) {
            console.error('Error loading news', error);
        }
    };

    useEffect(() => {
        fetchNews();
    }, [currentPage]);

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="container mt-5">

            <button onClick={() => setShowForm(!showForm)} className="btn btn-primary mb-3">
                {showForm ? 'Показать новости' : 'Добавить новость'}
            </button>

            {showForm && (
                <form onSubmit={handleSubmit} className="news-form shadow-lg p-4 bg-white rounded">
                    <div className="mb-3">
                        <label htmlFor="formTitle" className="form-label">Заголовок</label>
                        <input
                            type="text"
                            id="formTitle"
                            className="form-control"
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="formShortDescription" className="form-label">Краткое описание</label>
                        <input
                            type="text"
                            id="formShortDescription"
                            className="form-control"
                            placeholder="Enter short description"
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="formContent" className="form-label">Содержание</label>
                        <textarea
                            id="formContent"
                            className="form-control"
                            rows={4}
                            placeholder="Enter content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="formCategory" className="form-label">Категория</label>
                        <select
                            id="formCategory"
                            className="form-select"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required
                        >
                            <option value="" disabled>Выберите категорию</option>
                            <option value="Yangiliklar">Yangiliklar</option>
                            <option value="Foydali maqolalar">Foydali maqolalar</option>
                            <option value="E'lonlar">E'lonlar</option>
                            <option value="Tenderlar">Tenderlar</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="formPhoto" className="form-label">Загрузить фото</label>
                        <input
                            type="file"
                            id="formPhoto"
                            className="form-control"
                            onChange={handlePhotoChange}
                            accept="image/*"
                        />
                    </div>

                    {photo && (
                        <div className="photo-preview">
                            <p>Выбранное изображение: {photo.name}</p>
                            <img src={URL.createObjectURL(photo)} alt="Preview" className="img-thumbnail" style={{ width: '100px', height: 'auto' }} />
                        </div>
                    )}

                    <button type="submit" className="btn btn-success mt-3">
                    Сохранить новости
                    </button>
                </form>
            )}

            {!showForm && (
                <div className="news-list mt-4">
                    <table className="table table-striped table-bordered shadow-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Просмотры</th>
                                <th>Фото</th>
                                <th>Заголовок</th>
                                <th>Категория</th>
                                <th>Дата</th>
                            </tr>
                        </thead>
                        <tbody>
                            {newsList.length > 0 ? (
                                newsList.map((news) => (
                                    <tr key={news.id}>
                                        <td style={{paddingTop: '8px'}}>{news.id}</td>
                                        <td style={{paddingTop: '8px', textAlign: 'center'}}>{news.views}</td>
                                        <td>
                                            {news.photo ? (
                                                <img
                                                    src={news.photo}
                                                    alt="News Thumbnail"
                                                    className="img-thumbnail"
                                                    style={{ width: '50px', height: 'auto' }}
                                                />
                                            ) : (
                                                'No Image'
                                            )}
                                        </td>
                                        <td style={{paddingTop: '8px'}}>{news.title}</td>
                                        <td style={{paddingTop: '8px'}}>{news.category}</td>
                                        <td style={{paddingTop: '8px'}}>{new Date(news.created_at).toLocaleDateString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>No news available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <div className="pagination d-flex justify-content-center align-items-center">
                        <button onClick={handlePrevPage} className="btn btn-secondary mx-2" disabled={currentPage === 1}>
                            &laquo; Prev
                        </button>
                        <span className="mx-2 text-muted">{currentPage} of {totalPages}</span>
                        <button onClick={handleNextPage} className="btn btn-secondary mx-2" disabled={currentPage === totalPages}>
                            Next &raquo;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminAddNews;
